<template>
  <div class="app-container">
    <div style="display: flex">
      <el-button type="primary" v-p="['externaladmin:fedex:country:add']" @click="handleAdded" class="addButton"
        >+ 添加</el-button
      >
      <el-button
        v-p="['externaladmin:fedex:country:download']"
        type="primary"
        style="margin-left: -5px"
        plain
        size="mini"
        :loading="loading"
        @click="doLoading"
      >
        <el-tooltip
          class="item"
          effect="light"
          content="温馨提示：请先下载国家导入模板，按规范填写后导入"
          placement="bottom"
        >
          <a class="download-btn" href="/template.xlsx"> <i class="el-icon-download" ref="aLink"></i> 下载导入模板 </a>
        </el-tooltip>
      </el-button>
      <XlsxTable
        @getFileName="(fileName) => (moreForm.groupName = fileName.replace(/\.[a-zA-Z]+$/, ''))"
        @on-select-file="onSelectFile"
        style="display: inline-block; margin-left: 12px"
        v-p="['externaladmin:fedex:country:export']"
      >
        <el-button size="small" icon="el-icon-upload2">导入</el-button>
      </XlsxTable>
    </div>
    <div class="top-warpper" v-p="['externaladmin:fedex:country:search']">
      <div class="continent-warpper">
        所属洲：
        <span @click="clickAll" :class="[{ active: seletcedIndex === -1 }, 'all']">全部</span>
        <ul
          v-for="(state, index) in continentDictCode"
          :key="index"
          class="continent-whole"
          :class="{ active: seletcedIndex === index }"
          @click="searchName(index, state.id)"
        >
          <li>{{ state.itemName }}</li>
        </ul>
        <span style="margin-left: 9px">搜索类型：</span>
        <el-input
          placeholder="输入国家/地区名称、代码进行搜索"
          size="mini"
          style="width: 240px"
          clearable
          v-model="content"
          @change="search()"
          @clear="search()"
        >
          <i class="el-icon-search" style="line-height: 30px" slot="suffix" @click="search(item.index)"> </i
        ></el-input>
      </div>
    </div>
    <countryDialog
      :form="form"
      :id="id"
      :currency="currency"
      :addDialog.sync="addDialog"
      :type="type"
      :radio.sync="radio"
      :sup_this="sup_this"
    />
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    <CommonTable :selection="false" :tableLoading="tableLoading" :cols="cols" :infoData="data">
      <template #continentSlot="{ scoped: row }">
        {{ getCurrentcontinent(row) }}
      </template>
      <template #currencySlot="{ scoped: row }">
        {{ handleCurrency(row) }}
      </template>
      <template #currencyTypeSlot="{ scoped: row }">
        {{ row.currency || '暂无' }}
      </template>

      <slot>
        <el-table-column fixed="right" label="操作" width="300px" align="center">
          <template slot-scope="{ row }">
            <span style="color: #3841db; cursor: pointer" v-p="['externaladmin:fedex:country:edit']" @click="onEdit(row)"
              >编辑</span
            >
            <span class="line"></span>
            <Popover @sureHandler="onDelete(row.id)">
              <template #tip>
                <p>您确定要删除已添加的国家/地区么?</p>
              </template>
              <template #reference="{ scope: loading }">
                <color-text-btn
                  v-p="['externaladmin:fedex:country:delete']"
                  class="update-btn"
                  :loading="loading"
                  size="mini"
                  type="danger"
                  >删除</color-text-btn
                >
              </template>
            </Popover>
          </template>
        </el-table-column>
      </slot>
    </CommonTable>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    <el-dialog
      v-if="previewDialogVisible"
      :before-close="handleClose"
      title="国家导入预览"
      :visible.sync="previewDialogVisible"
      width="1100px"
      :close-on-click-modal="false"
    >
      <previewTable
        :state.sync="importCountry"
        :fileListData="fileList"
        :countryForm="form1"
        :errData="errData"
        ref="previewTableRef"
      >
        <template #center>
          <avue-form v-model="moreForm" :option="moreFormOption"></avue-form>
        </template>
      </previewTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :disabled="importCountry" :loading="orderLoading" type="primary" @click="previewDialog"
          >确定导入</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteCounrty, batchCreate } from '@/api/country'
import XlsxTable from '@/components/xlsxTable.vue'
import { getList } from '@/api/currency'
import { changeArrKey } from '@/utils'
import { delMixin } from '@/mixins'
import { countryListCols as cols } from '@/views/fedex/cols'
import { mapGetters } from 'vuex'
import initDataMixin from '@/mixins/initData'
import { debounce } from '@/utils'
import countryDialog from './module/countryDialog.vue'
import { COUNTRY_IMPORT_KEYS } from '@/utils/constant'
import previewTable from './module/previewTable'
import { deepClone } from '@/components/avue/utils/util'

// const temUrl = require('template.xlsx')

export default {
  mixins: [initDataMixin, delMixin],
  components: { countryDialog, XlsxTable, previewTable },
  data() {
    return {
      sup_this: this,
      importCountry: false,
      cols,
      // temUrl,
      url: '/externaladmin/systemService/countryCode/complexList',
      method: 'post',
      addDialog: false,
      orderLoading: false,
      form1: {},
      mergeData: {
        isDeleted: 0
      },
      moreForm: {
        groupName: ''
      },
      moreFormOption: {
        submitBtn: false,
        emptyBtn: false,
        span: 8
      },
      fileList: [],
      errData: {
        failCount: 0,
        errMsg: []
      },
      previewDialogVisible: false,
      query: {},
      currency: [],
      seletcedIndex: -1,
      currentType: '国家/地区中文名称',
      id: null,
      content: '',
      continent: null,
      countrycn: null,
      countryen: null,
      counrtyId: null,
      code: null,
      type: 'add',
      radio: null,
      form: {
        continent: null,
        countrycn: null,
        countryen: null,
        code: null,
        currentTax: null,
        radio: null
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters(['continentDictCode'])
  },
  watch: {
    previewDialogVisible(newVal) {
      if (!newVal) {
        this.moreForm = {
          groupName: '',
          remark: ''
        }
      }
    }
  },
  methods: {
    handleClose() {
      this.$refs.previewTableRef.$refs.countryRef.clearValidate()
      this.previewDialogVisible = false
      this.importCountry = false
    },
    uniqueArray(arr) {
      return arr.filter((item, index, arr) => arr.indexOf(item) === index)
    },
    async previewDialog() {
      let countryForm = this.$refs.previewTableRef.countryForm
      let tmp = []
      for (let key in countryForm) {
        const [fKey, num] = key.split('_')
        if (!tmp[num]) {
          tmp[num] = {}
        }
        tmp[num][fKey] = countryForm[key]
      }

      this.$refs.previewTableRef.$refs.countryRef.validate((valid) => {
        if (!valid) {
          this.importCountry = true
          return
        }
        if (this.errData.errMsg) {
          this.$message.error(this.errData.errMsg)
          return
        }
        // 确定导入按钮
        this.orderLoading = true
        tmp.map((item) => {
          if (item.twoCharCode) {
            item.twoCharCode = item.twoCharCode.toUpperCase()
          }
        })
        //拷贝上传的表格数据
        let copy = deepClone(tmp)
        copy.map((item) => {
          //将表格数据中的所属洲转换为接口需要的id
          if (item.continentDictCode) {
            item.continentDictCode = this.continentDictCode.find((code) => {
              return code.itemName === item.continentDictCode
            }).id
          }
          //将表格数据中的报关金额币种转换为接口需要的id
          if (item.currency) {
            const res = this.currency.find((type) => {
              return type.currencyName === item.currency
            })
            if (!res) {
              this.$message.error('请输入正确的报关金额币种')
              return
            }
            item.currencyId = res.id
          }
          //将表格中的是否需要填写税号转换为接口需要的数字类型
          if (item.prepaymentType) {
            if (item.prepaymentType === 'IOSS税号') {
              item.prepaymentType = 0
            } else if (item.prepaymentType === '英国税号') {
              item.prepaymentType = 1
            } else {
              item.prepaymentType = 2
            }
          }
        })
        const data = {
          list: copy
        }
        batchCreate(data)
          .then((res) => {
            this.orderLoading = false
            this.previewDialogVisible = false
            this.init()
            this.$message.success('批量添加国家成功')
          })
          .catch(() => {
            this.orderLoading = false
          })
      })
    },
    doLoading() {
      this.loading = true
      const { aLink } = this.$refs
      if (aLink) {
        console.log('aLink', aLink)
        aLink.click()
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    check(data) {
      let errData = []
      if (data.length === 0) {
        errData.push('上传表格无数据')
        setTimeout(() => {
          this.errData = {
            errData, // 错误信息集合 array
            failCount: 0, // 产品错误数量
            errMsg: '上传表格无数据', // 错误信息字符串 - 用于this.$message.error(this.errData.errMsg)
            // 错误字段集合
            errKey: {
              orderCode: {}, // 多个产品时，唯一值不同全为failed
              index: {} // 无订单号时，视为单一产品
            }
          }
          this.$message.error('上传表格无数据')
        })
        return false
      }

      // 产品数据错误数量
      let failCount = 0
      // 键为运单号
      // 值为同一运单号的订单号数组
      let waybillCode = {}
      // 键为订单号
      // 值为对象（键值对）：键为对订单号唯一字段名，值为字段值数组
      let codeObj = {}
      // 物流方式不存在或已删除
      let withoutExpressCompanyName = []
      let errKey = {
        orderCode: {}, // 多个产品时，唯一值不同全为failed
        index: {} // 无订单号时，视为单一产品
      }

      for (const type in errKey) {
        for (const value in errKey[type]) {
          // errKey[type][value] 当前订单（包括所有产品）
          let currentOrder = errKey[type][value]
          // let indexes = Object.keys(currentOrder[ORDER_CODE_NON_UNIQUE_KEYS[0]])
          // // 订单号下的产品数量
          // let proCount = indexes.length

          // // 唯一值有一个为true，则该订单号下的所有产品解析失败
          // if (ORDER_CODE_UNIQUE_KEYS.some((key) => currentOrder[key])) {
          //   failCount += proCount
          //   continue
          // }

          // let proFailedObj = {}
          let proFailedCount = 0
          // ORDER_CODE_NON_UNIQUE_KEYS.map((key) => {
          //   indexes.map((index) => {
          //     if (proFailedObj[index]) {
          //       return
          //     }
          //     if (currentOrder[key][index]) {
          //       proFailedObj[index] = true
          //       proFailedCount++
          //     }
          //   })
          // })
          // console.log(isFailed, proCount, proFailedCount)
          failCount += proFailedCount
        }
      }

      // console.log(errData);
      errData = this.uniqueArray(errData)
      let errMsg = errData.join('；')
      if (errData.length) {
        setTimeout(() => {
          this.$message.error(`${errMsg}`)
        })
      }
      this.errData = {
        errMsg,
        errData,
        failCount,
        errKey
      }
      // console.log(this.errData)
      return failCount === 0
    },
    // getDicValue(label, dic = [], props = { label: 'label', value: 'value' }) {
    //   // console.log(value, dic, props);
    //   let item = dic.find((item) => item[props.label] === label)
    //   return item ? item[props.value] : ''
    // },
    async onSelectFile(excelData) {
      const loading = this.$loading({
        lock: true,
        text: '上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      try {
        const { body = [] } = excelData
        this.previewDialogVisible = true
        this.fileList = []
        this.fileList = changeArrKey(body, COUNTRY_IMPORT_KEYS)

        console.log('dsadasdsa', excelData, body, this.fileList)
        const form1 = {}
        this.fileList.map((item, index) => {
          for (let k in item) {
            form1[`${k}_${index}`] = item[k]
          }
          console.log('item', item)
        })
        this.form1 = form1
        console.log('this.form ', this.form1)
        this.check(this.fileList)
      } catch (err) {
        console.log(err)
      }
      loading.close()
    },
    handleCurrency(data) {
      const type = data.prepaymentType
      if (type === 0) {
        return 'IOOS税号'
      } else if (type === 1) {
        return '英国税号'
      } else {
        return '无'
      }
    },
    handleSelected(type) {
      this.currentType = type
    },
    async getCurrency() {
      const data = {
        page: {
          pageIndex: 1,
          pageSize: 0
        }
      }
      await getList(data).then((res) => {
        this.currency =
          res.detail ||
          [].map((item) => ({
            currency: item.currency,
            id: item.id
          }))
      })
    },
    async handleAdded() {
      await this.getCurrency()
      if (this.currency.length === 0) {
        this.$message.error('当前币种数量为0，请先添加币种')
        this.$router.push('/fedex/currencyManage')
        return
      }
      this.$set(this.form, 'currentTax', this.currency[0].id)
      // this.form.currentTax = this.currency[0].id;
      this.type = 'add'
      this.radio = this.continentDictCode[0].id
      this.form.radio = 0
      this.form.continent = this.continentDictCode[0].id
      this.form.countrycn = null
      this.form.countryen = null
      this.form.code = null
      // this.form = {
      //   continent: this.continentDictCode[0].id,
      //   countrycn: null,
      //   countryen: null,
      //   code: null,
      // };
      this.addDialog = true
    },
    getCurrentcontinent(row) {
      const id = row.continentDictCode
      const currentItem = this.continentDictCode.find((item) => item.id === id)
      if (!currentItem) return
      else return currentItem.itemName
    },
    searchName: debounce(function (index, id) {
      this.seletcedIndex = index
      this.query.continentDictCode = id
      this.countryId = id
      this.searchChange()
    }),
    async onDelete(idList) {
      const id = [idList]
      try {
        await deleteCounrty({ idList: id })
        this.init()
        this.$message.success('国家删除成功！')
      } catch (error) {
        this.$message.success('国家删除失败！')
      }
    },
    async onEdit(data) {
      await this.getCurrency()
      this.type = 'edit'
      const { continentDictCode, countryCnName, countryEnName, twoCharCode, id, prepaymentType, currencyId } = data
      this.radio = continentDictCode
      this.form.radio = prepaymentType
      this.form.currentTax = currencyId
      this.id = id
      this.continent = continentDictCode
      this.countrycn = countryCnName
      this.countryen = countryEnName
      this.code = twoCharCode
      this.form.continent = continentDictCode
      this.form.countrycn = countryCnName
      this.form.countryen = countryEnName
      this.form.code = twoCharCode
      this.addDialog = true
    },
    clickAll() {
      delete this.query.continentDictCode
      this.query.twoCharCode = this.content || undefined
      this.seletcedIndex = -1
      this.searchChange()
    },
    search() {
      this.query.twoCharCode = this.content
      this.searchChange()
    }
  },
  created() {
    this.getCurrency()
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-direction: column;
  .top-warpper {
    display: flex;
    align-items: center;
    margin-top: 20px;
    background-color: #f0f2f5;
    padding: 20px;
    .continent-warpper {
      width: 1200px;
      font-size: 14px;
      display: flex;
      align-items: center;
      .active {
        color: #3841db;
        border: 1px solid #3841db !important;
        border-radius: 2px !important;
      }
      .actived {
        color: #3841db;
      }
      .all,
      .continent-whole {
        padding: 10px;
        border: 1px solid #dcdee0;
        border-radius: 2px;
        margin-right: 12px;
        cursor: pointer;
        li {
          list-style: none;
        }
      }
    }
  }
  .popover {
    ::v-deep {
      .el-popover {
        padding: 0;
        border: none;
      }
    }
  }
  .dialog-footer {
    ::v-deep {
      .el-button {
        width: 62px;
        height: 32px;
        border-radius: 2px;
        padding: 0;
      }
    }
  }
}

.addButton,
.searchButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.addButton {
  width: 72px;
  height: 32px;
  margin-right: 20px;
}
.searchButton {
  width: 48px;
  height: 32px;
}
</style>
